import $ from 'jquery'
import React, { Component } from 'react'
import { Link, navigate } from 'gatsby'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
  TabContent,
  TabPane,
  Row,
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import classnames from 'classnames'
import Logo from './../../assets/images/logo.png'
import Search from './../../assets/images/search.svg'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { message } from 'antd'
import NumberFormat from 'react-number-format'
import { HOSTNAME } from '../config'

const cookies = new Cookies()

let initialState = {
  loginType: 'email',
  email: '',
  phoneNumber: '',
  err: {},
  resMessage: '',
  resType: '',
  isLoading: false,
  noAccountMsg: false,
}
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const user = cookies.get('user')
    if (user && user !== 'undefined') {
      // setTimeout(function() {
      navigate('/')
      // }, 1000)
    } else {
      setTimeout(function() {
        document.title = 'Login | American Field'
        let meta = document.getElementsByTagName('meta')
        for (var i = 0; i < meta.length; i++) {
          if (meta[i].name.toLowerCase() === 'description') {
            meta[i].content = 'Login | American Field'
          }
          if (meta[i].name.toLowerCase() === 'url') {
            meta[i].content = 'https://app.shopaf.co/login'
          }
        }
      }, 300)
    }
  }

  handleChange(e) {
    if (e.target.name === 'email') {
      this.state.err.email = ''
    }
    if (e.target.name === 'phoneNumber') {
      this.state.err.phoneNumber = ''
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  submitForm(event) {
    event.preventDefault()
    const { email, phoneNumber, loginType } = this.state
    let self = this
    const err = {}
    if (loginType === 'email') {
      if (email === '' || email.trim() === '') {
        err.email = 'Email is required'
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        err.email = 'Invalid email'
      }
    } else {
      if (phoneNumber === '' || phoneNumber.trim() === '') {
        err.phoneNumber = 'Please enter your phone number'
      }
    }
    this.setState({ err })
    if (!Object.keys(err).length) {
      window &&
        window.ga &&
        window.ga('send', 'event', {
          eventCategory: 'Login',
          eventAction: 'Login Submit Clicked',
          eventLabel: 'Login Submit Clicked',
          transport: '1',
        })
      let obj = {}
      obj.loginType = loginType
      if (loginType === 'email') {
        obj.email = String(email).toLowerCase()
      } else {
        obj.phoneNumber = phoneNumber.replace(/\D/g, '')
      }
      this.setState({ isLoading: true })
      axios
        .post(HOSTNAME + 'login', obj)
        .then(function(response) {
          let res = response.data
          if (res.success) {
            res.data.loginType = loginType
            self.setState({
              isLoading: false,
              resMessage: res.message,
              resType: 'alert alert-success',
            })
            setTimeout(function() {
              if (loginType === 'phone') {
                navigate('otp', { state: { userData: res.data } })
              }
            }, 200)
          } else {
            if (res.key && res.key === 'no_account') {
              self.setState({ isLoading: false, noAccountMsg: true })
              setTimeout(function() {
                self.setState({ noAccountMsg: false })
              }, 10000)
            } else {
              self.setState({
                isLoading: false,
                resMessage: res.message,
                resType: 'alert alert-danger',
              })
              setTimeout(function() {
                self.setState({ resMessage: '', resType: '' })
              }, 10000)
            }
          }
        })
        .catch(function(error) {
          self.setState({
            isLoading: false,
            resMessage: error,
            resType: 'alert alert-danger',
          })
        })
    }
  }

  selectLoginType(key) {
    this.setState({ loginType: key, err: {}, resMessage: '', resType: '' })
    let label =
      key === 'email'
        ? 'Login Type Email Clicked'
        : 'Login Type Phone Number Clicked'
    window &&
      window.ga &&
      window.ga('send', 'event', {
        eventCategory: 'Login Type',
        eventAction: label,
        eventLabel: label,
        transport: '1',
      })
  }

  render() {
    const {
      loginType,
      email,
      phoneNumber,
      err,
      resMessage,
      resType,
      isLoading,
      noAccountMsg,
    } = this.state
    return (
      <div>
        <div className="login__page">
          <div className="login__header">
            <button className="btn header_btn">
              <Link to={'/signup'}>SIGN UP</Link>
            </button>
          </div>
          <div className="vertical__align-center">
            <Form className="form">
              <div className="logo__box text-center">
                <img src={Logo} />
                <h2 className="form_title">Login to Your AF Marketplace</h2>
              </div>
              <div className="form__inner">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <div className="custome__form--tabs custome__tabs">
                      {/*<Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: loginType === 'email',
                            })}
                            onClick={this.selectLoginType.bind(this, 'email')}
                          >
                            Email
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: loginType === 'phone',
                            })}
                            onClick={this.selectLoginType.bind(this, 'phone')}
                          >
                            Phone Number
                          </NavLink>
                        </NavItem>
                      </Nav>*/}
                      <p className="small_title text-center">
                        Having trouble logging in? Contact <a className="header_link" href="mailto:support@shopaf.co" target="_top">support@shopaf.co</a>
                      </p>
                      <TabContent activeTab={loginType}>
                        <TabPane tabId="email">
                          <Row>
                            <Col sm="12" className="text-left">
                              <FormGroup>
                                <Label for="exampleEmail">EMAIL ADDRESS</Label>
                                <Input
                                  type="text"
                                  name="email"
                                  value={email}
                                  onChange={this.handleChange}
                                  placeholder=""
                                />
                                <FormFeedback>
                                  {'email' in err ? err.email : ''}{' '}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="phone">
                          <Row>
                            <Col sm="12" className="text-left">
                              <FormGroup>
                                <Label for="exampleEmail">PHONE NUMBER</Label>
                                <NumberFormat
                                  className="form-control"
                                  name="phoneNumber"
                                  value={phoneNumber}
                                  onChange={this.handleChange}
                                  format="(###) ###-####"
                                  mask="_"
                                />
                                <FormFeedback>
                                  {'phoneNumber' in err ? err.phoneNumber : ''}{' '}
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
              </div>
              {resMessage !== '' && (
                <div className={resType}>
                  <strong>{resMessage}</strong>
                </div>
              )}
              {noAccountMsg && (
                <div className="alert alert-danger">
                  <strong>
                    Looks like you don’t have an account.{' '}
                    <Link to={'/signup'}>Sign up here</Link>
                  </strong>
                </div>
              )}
              {isLoading ? (
                <Button className="btn btn-primary btn-block btn-lg signup_btn">
                  Requesting...
                </Button>
              ) : (
                <Button
                  className="btn btn-primary btn-block btn-lg signup_btn"
                  onClick={this.submitForm.bind(this)}
                >
                  SIGN IN
                </Button>
              )}
              <Link className="login_text text-center" to={'/signup'}>
                Don't have an account? <span>Sign Up</span>
              </Link>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
