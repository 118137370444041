import React from 'react'
import Layout from '../components/layout'
import Login from '../components/login/component'

const NotFoundPage = props => (
  <Layout>
    <Login {...props} />
  </Layout>
)

export default NotFoundPage
